import React, { Component } from 'react';
import Field from './Field.js';

import sqLogo from './logo.png';

const FieldList = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Full name',
  },
  {
    name: 'title',
    type: 'text',
    placeholder: 'Job title',
  },
  {
    name: 'email',
    type: 'text',
    placeholder: 'Email address',
  },
  {
    name: 'phone',
    type: 'text',
    placeholder: 'Phone number',
  },
  {
    name: 'calendly',
    type: 'text',
    placeholder: 'Calendly link'
  },
  // {
  //   name: 'linkedin',
  //   type: 'text',
  //   placeholder: 'LinkedIn profile',
  // },
  // {
  //   name: 'twitter',
  //   type: 'text',
  //   placeholder: 'Twitter profile',
  // },
  // {
  //   name: 'facebook',
  //   type: 'text',
  //   placeholder: 'Facebook',
  // },
  // {
  //   name: 'github',
  //   type: 'text',
  //   placeholder: 'Github',
  // },
  // {
  //   name: 'address_line_1',
  //   type: 'text',
  //   placeholder: 'Address Line 1'
  // },
  // {
  //   name: 'address_line_2',
  //   type: 'text',
  //   placeholder: 'Address Line 2'
  // },
  // {
  //   name: 'address_line_3',
  //   type: 'text',
  //   placeholder: 'Address Line 3'
  // }
];


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: 'Firstname Lastname',
      title: 'Job title',
      email: 'firstname@voyage.studio',
      phone: '000',
      linkedin: 'https://linkedin.com/company/voyage-studio',
      facebook: 'https://www.facebook.com/vygnz',
      github: 'https://github.com/vyg',
      twitter: 'https://twitter.com/vygnz',
      instagram: 'https://instagram.com/voyage.studio',
      address_line_1: '192 St Asaph Street',
      address_line_2: 'Christchurch, 8011',
      address_line_3: 'New Zealand',
      calendly: 'https://cal.frontapp.com/voyage/ryan/5da61b49',
      website: 'https://voyage.studio/?utm_source=signature&utm_medium=email'
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = e => {
    const key = e.target.name;
    const value = e.target.value;

    this.setState({
      [key]: value 
    });
  }
  
  render() {
    return (
      <div className="">
        <header className="">
          <h1>Email signature</h1>
        </header>

        <form>
          {FieldList.map((field, index) => (
            <Field
              key={index}
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              onChange={this.handleChange}
            />
          ))}
        </form>

        <div>
          <p className="notes">Copy and paste the below in to your email client.</p>

          <br />
          <br />

          {/* <!-- Signature start --> */}

          <table style={styles.table}>
            <tbody>

              <tr>
                <td style={styles.contentTd}>
                  <p style={styles.p}><strong>{this.state.name}</strong> / {this.state.title}</p>
                  <p style={styles.p}>E: <a href={`mailto:${this.state.email}`} target="_blank" rel="noopener noreferrer" style={styles.link}>{this.state.email}</a><br />
                  P: {this.state.phone}<br />
                  W: <a href={this.state.website} target="_blank" rel="noopener noreferrer">https://voyage.studio</a></p>

                  { this.state.calendly ? <p style={styles.p}><a href={this.state.calendly} target="_blank" rel="noopener noreferrer" style={styles.link}>Book a meeting</a></p> : null }
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style={styles.imgTd}>
                  <a href={this.state.website} target="_blank" rel="noopener noreferrer">
                    <img src={sqLogo} width="140" alt="Voyage" />
                  </a>
                </td>
              </tr>
              <tr>
                <td style={styles.contentTd}>
                  <p style={styles.p}>Christchurch | Wellington | Remote</p>
                </td>
              </tr>
            </tbody>
          </table>

          {/* <!-- Signature end --> */}
        </div>
      </div>
    );
  }
}

const styles = {
  table: {
    width: '100%',
    backgroundColor: 'rgb(255,255,255)',
  },
  p: {
    backgroundColor: 'rgb(255,255,255)',
    margin: '0px 0px 10px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: '10px',
    lineHeight: '12px',
    color: 'rgb(33,33,33)',
  },
  link: {
    color: 'rgb(71,124,204)',
    display: 'inline',
    backgroundColor: 'transparent', 
  },
  img: {
    marginTop: '20px'
  },
  imgTd: {
    width: '140px'
  },
  contentTd: {
    width: '100%'
  }
};

export default App;
