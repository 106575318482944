import React from "react";

const Field = ({name, type, placeholder, onChange}) =>  (
    <div className="field" style={{ marginBottom: 8 }}>
        <label htmlFor={name}>
            <strong>{placeholder}</strong>
            <input type={type} id={name} placeholder={placeholder} name={name} onChange={onChange} />
        </label>
    </div>
);

export default Field;